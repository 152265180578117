<template>
	<!-- 项目节点设置 -->
	<div class="">
		<el-form>
			<el-form-item>
				<el-row>
					<el-col :span="pageSpan">
						<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline" v-if="showAdd"
							@click="addOrUpdateHandle()">新增节点</el-button>
					</el-col>
					<el-col :span="pageSpan" style="text-align: right;">
						<el-pagination
						  background
						  @size-change="sizeChangeHandle"
						  @current-change="currentChangeHandle"
						  layout="prev, pager, next"
						  :pager-count="5"
						  :current-page="pageIndex"
						  :page-size="pageSize"
						  :total="totalPage">
						</el-pagination>
					</el-col>
				</el-row>
			</el-form-item>
		</el-form>
		<div :style="siteContentViewHeight()">
			<el-card style="height: 100%;" body-style="height: 100%; padding: 0;">
				<el-table
					class="dataListTable"
					:data="dataList"
					header-align="center"
					height="100%"
					:row-class-name="getRowClass"
					style="width: 100%;"
					header-cell-class-name="stepPointTHeader">
					<el-table-column width="30" type="expand" prop="records">
						<template slot-scope="props">
							<el-table :data="props.row.records" size="small" border header-align="center">
								<el-table-column
								  align="center"
								  type="index"
								  width="50">
								</el-table-column>
								<el-table-column align="center" label="变更时间" width="200" prop="changeTimes">
								</el-table-column>
								<el-table-column align="left" label="变更原因" prop="changeRsn" header-align="center">
								</el-table-column>
								<el-table-column align="center" label="操作" v-if="showChangePlanDelete">
									<template slot-scope="scope">
										<el-button v-preventReClick type="danger" size="small" @click="deleteHandle(scope.row.recNo,scope.row.changeTime)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</template>
					</el-table-column>
					<el-table-column prop="nodeName" label="节点" width="120" align="center">
					</el-table-column>
					<el-table-column prop="planTime" label="计划日期" width="110" align="center">
					</el-table-column>
					<el-table-column prop="actualTime" label="实际日期" width="110" align="center">
					</el-table-column>
					<el-table-column prop="changeTimes" label="调整次数" width="110" align="center">
					</el-table-column>
					<el-table-column prop="remark" label="描述" align="left" header-align="center">
					</el-table-column>
					<el-table-column fixed="right" header-align="center" align="center" width="350" label="操作" v-if="showChangePlan || showConfirm || showEdit || showDelete">
						<template slot-scope="scope">
							<el-button v-preventReClick type="primary" size="mini" @click="adjustmentHandle(scope.row.nodeId)" v-if="showChangePlan">调整计划 </el-button>
							<el-button v-preventReClick type="success" size="mini" @click="confirmHandle(scope.row.nodeId)" v-if="showConfirm">确认</el-button>
							<el-button v-preventReClick type="warning" size="mini" @click="addOrUpdateHandle(scope.row.nodeId)" v-if="showEdit">修改</el-button>
							<el-button v-preventReClick type="danger" size="mini" @click="deleteNodeHandle(scope.row.nodeId)" v-if="showDelete">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-card>
		</div>
		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
        <adjustment-handle v-if="adjustmentVisible" ref="adjustment" @refreshDataList="getDataList"></adjustment-handle>
		<confirm-handle v-if="confirmVisible" ref="confirm" @refreshDataList="getDataList"></confirm-handle>
	</div>
</template>
<script>
	import AddOrUpdate from './project-nodeList-add-or-update'
    import AdjustmentHandle from './project-nodeList-adjustment'
	import ConfirmHandle from './project-nodeList-confirm'
	import $common from "@/utils/common.js"
	export default {
		data() {
			return {
				pageSpan: 12,
				showSearch: false,
				showAdd: false,
				showChangePlan: false,
				showChangePlanDelete: false,
				showConfirm: false,
				showEdit: false,
				showDelete: false,
				addOrUpdateVisible: false,
                adjustmentVisible: false,
				confirmVisible: false,
				dataList: [],
				dataListSelections: [],
				dataForm:{
					workNo:""
				},
				//当前页码
				pageIndex: 1,
				//当前记录数
				pageSize: 10,
				//总页数
				totalPage: 0,
			};
		},
		components: {
			AddOrUpdate,
            AdjustmentHandle,
			ConfirmHandle
		},
		mounted() {
			this.showSearch = $common.isAuth('nodeList:search');
			this.showAdd = $common.isAuth('nodeList:add');
			this.showChangePlan = $common.isAuth('nodeList:change:plan');
			this.showChangePlanDelete =$common.isAuth('nodeList:change:plan:delete');
			this.showConfirm = $common.isAuth('nodeList:confirm');
			this.showEdit = $common.isAuth('nodeList:edit');
			this.showDelete = $common.isAuth('nodeList:delete');
			if (!this.showAdd){
				this.pageSpan = 24;
			}
			if (this.showSearch){
				this.getDataList();
			}
		},
		methods: {
			// 获取数据列表
			getDataList() {
				this.$http({
					url:  this.$store.state.httpUrl + "/business/projectnodeinfo/list",
					method: 'get',
					params: this.$http.adornParams({
						page: this.pageIndex,
						limit: this.pageSize,
						workNo: $common.getItem("workNo")
					})
				}).then(({data}) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
					} else {
						this.dataList = []
						this.totalPage = 0
					}
				})
			},
			selectionChangeHandle (val) {
				this.dataListSelections = val
			},
			//自适应高度
			siteContentViewHeight() {
				var height = this.$store.state.common.documentClientHeight - 160;
				return {
					height: height + 'px'
				}
			},
			// 每页数
			sizeChangeHandle(val) {
				this.pageSize = val
				this.pageIndex = 1
				this.getDataList()
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageIndex = val
				this.getDataList()
			},
			//选中背景变色
			getRowClass (row, rowIndex) {
				if (!row.row.records || row.row.records.length === 0) {
					return 'row-expand-cover'
				}
				return ''
			},
			// 新增节点
			addOrUpdateHandle(id) {
				this.addOrUpdateVisible = true
				this.$nextTick(() => {
					this.$refs.addOrUpdate.init(id)
				})
			},
            // 调整修改节点
			adjustmentHandle(id) {
				this.adjustmentVisible = true
				this.$nextTick(() => {
					this.$refs.adjustment.init(id)
				})
			},
			// 确认调整修改
			confirmHandle(id) {
				this.confirmVisible = true
				this.$nextTick(() => {
					this.$refs.confirm.init(id)
				})
			},
			deleteNodeHandle(id){
				this.$confirm(`您确定对选中项目节点记录进行删除操作?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url:  this.$store.state.httpUrl + "/business/projectnodeinfo/delete",
						method: 'post',
						data: this.$http.adornData([id], false)
					}).then(({data}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.getDataList()
								}
							})
						} else {
							this.$message.error(data.msg)
						}
					})
				})
			},
            // 删除
            deleteHandle (id,changeTime) {
				this.$confirm(`您确定对选中记录进行删除操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
						url:  this.$store.state.httpUrl + "/business/projectnodechangeinfo/delete",
                        method: 'post',
						data: this.$http.adornData([id], false)
                    }).then(({data}) => {
                        if (data && data.resultCode === 200) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList()
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            },

		}
	};
</script>
<style lang="scss" scoped>
</style>
